<template>
  <b-card title="Novo Técnico">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <!-- Código -->
          <b-col md="2">
            <b-form-group
              label="Código"
              label-for="h-codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-form-input
                  id="h-codigo"
                  v-model="tecnico.codigo"
                  placeholder="Código"
                  maxlength="15"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="7">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-input
                  id="h-nome"
                  v-model="tecnico.nome"
                  placeholder="Nome"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
          >
            <b-form-group
              label="Login"
              label-for="login"
            >
              <validation-provider
                #default="{ errors }"
                name="login"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="login"
                    v-model="utilizador.username"
                    type="text"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalUtilizadores"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="tecnico.email"
                  type="email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Telemovel"
              label-for="h-telemovel"
            >
              <validation-provider
                #default="{ errors }"
                name="Telemovel"
                rules="required"
              >
                <b-form-input
                  id="h-telemovel"
                  v-model="tecnico.telemovel"
                  placeholder="Telemovel"
                  maxlength="13"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="Custo Hora"
              label-for="h-horaCusto"
            >
              <validation-provider
                #default="{ errors }"
                name="horaCusto"
                rules="required"
              >
                <b-form-input
                  v-model="tecnico.horaCusto"
                  type="number"
                  class="mb-2"
                  placeholder="0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Valor Hora"
              label-for="h-horaPreco"
            >
              <validation-provider
                #default="{ errors }"
                name="horaPreco"
                rules="required"
              >
                <b-form-input
                  v-model="tecnico.horaPreco"
                  type="number"
                  class="mb-2"
                  placeholder="0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <b-modal
      ref="modalUtilizadores"
      hide-footer
      size="xl"
    >

      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Pesquisar</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Pesquisar"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columnsUtilizadores"
        :rows="rowsUtilizadores"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'username'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.username }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'email'">

            {{ props.row.email }}

          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="selectUtilizador(props.row)"
                >
                  Selecionar
                </b-button>
              </div>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrar 1 até
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap "> de {{ props.total }} registos </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton, BInputGroupPrepend,
  BInputGroup, BPagination, BFormSelect, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BPagination,
    BFormSelect,
    BModal,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      boxTwo: false,
      pageLength: 3,
      searchTerm: '',
      rowsUtilizadores: [],
      RetypePassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      columnsUtilizadores: [
        {
          label: 'Username',
          field: 'username',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      tecnico: {
        codigo: '',
        nome: '',
        email: '',
        telemovel: '',
        horaCusto: 0,
        horaPreco: 0,
        login: '',
      },
      utilizador: {
        id: '',
        username: '',
        nome: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getUtilizadores()
    localize('pt', pt)
  },
  methods: {
    getUtilizadores() {
      axios.get('/api/v1/users')
        .then(res => { this.rowsUtilizadores = res.data })
      this.$forceUpdate()
    },

    showModalUtilizadores() {
      this.$forceUpdate()
      this.$refs.modalUtilizadores.show()
    },
    hideModalUtilizadores() {
      this.$refs.modalUtilizadores.hide()
    },
    selectUtilizador(row) {
      this.tecnico.login = row.id
      this.utilizador.id = row.id
      this.utilizador.nome = row.nome
      this.utilizador.username = row.username

      this.hideModalUtilizadores()
    },
    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.guardarTecnico()
        }
      })
    },
    guardarTecnico() {
      axios.post('/api/v1/tecnicos', this.tecnico)
        .then(this.showToast('success', 'CheckIcon'))
    },

    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
